import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a382885"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup-input-label" }
const _hoisted_2 = { class: "dual-textbox" }
const _hoisted_3 = { class: "popup-input-label mt-3" }
const _hoisted_4 = { class: "popup-input-label permission-label mt-3" }
const _hoisted_5 = { class: "group-checkbox" }
const _hoisted_6 = { class: "popup-input-label mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidationTextField = _resolveComponent("ValidationTextField")!
  const _component_PermissionCheckBox = _resolveComponent("PermissionCheckBox")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_openBlock(), _createBlock(_component_Popup, {
    title: _ctx.$t('adminManagement.titlePopup'),
    "popup-key": _ctx.popupKey
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FlatButton, {
        class: "w-full",
        text: 
          _ctx.form.values.id
            ? _ctx.$t('adminManagement.change')
            : _ctx.$t('adminManagement.add')
        ,
        "on-click": _ctx.submit
      }, null, 8, ["text", "on-click"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("adminManagement.signerName")), 1),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_ValidationTextField, {
          value: _ctx.adminData.lastName,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.adminData.lastName) = $event)),
          name: "lastName",
          placeholder: _ctx.$t('adminManagement.lastName'),
          rules: "required",
          message: {
          error_required: _ctx.$t('errors.required'),
        }
        }, null, 8, ["value", "placeholder", "message"]),
        _createVNode(_component_ValidationTextField, {
          value: _ctx.adminData.firstName,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.adminData.firstName) = $event)),
          name: "firstName",
          placeholder: _ctx.$t('adminManagement.firstName'),
          rules: "required",
          message: {
          error_required: _ctx.$t('errors.required'),
        }
        }, null, 8, ["value", "placeholder", "message"])
      ]),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("adminManagement.mailAddress")), 1),
      _createVNode(_component_ValidationTextField, {
        value: _ctx.adminData.email,
        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.adminData.email) = $event)),
        name: "email",
        rules: "required|email",
        message: {
        error_required: _ctx.$t('errors.required'),
        error_format_email: _ctx.$t('errors.formatEmail'),
      },
        placeholder: _ctx.$t('common.email')
      }, null, 8, ["value", "message", "placeholder"]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("adminManagement.permission")), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("adminManagement.browsing")), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("adminManagement.update")), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.adminData, (_, name) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: name }, [
            (_ctx.getPermissionName(name))
              ? (_openBlock(), _createBlock(_component_PermissionCheckBox, {
                  key: 0,
                  modelValue: _ctx.adminData[name],
                  "onUpdate:modelValue": ($event: any) => ((_ctx.adminData[name]) = $event),
                  name: name,
                  "is-field": ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getPermissionName(name)), 1)
                  ]),
                  _: 2
                }, 1032, ["modelValue", "onUpdate:modelValue", "name"]))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("company.editPartnerCompanyNameLabel")), 1),
      _createVNode(_component_Dropdown, {
        options: _ctx.list_referral_company,
        "item-text": "name",
        "item-value": "id",
        "onUpdate:modelValue": _ctx.selectFile,
        "model-value": _ctx.editValueId
      }, null, 8, ["options", "onUpdate:modelValue", "model-value"])
    ]),
    _: 1
  }, 8, ["title", "popup-key"]))
}