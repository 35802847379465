
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
  toRefs,
} from "vue";
import FlatButton from "@/components/atomics/button/FlatButton.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import {
  AdminManagementRepository,
  OnboardingRepository,
  RepositoryFactory,
} from "@/lib/https";
import { useI18n } from "vue-i18n";
import Admin from "@/models/Admin";
import useFormHandler from "@/lib/compositional-logic/useFormHandler";
import Popup from "@/components/popups/PopupBase.vue";
import usePopupControl, {
  PopupKey,
} from "@/lib/compositional-logic/usePopupControl";
import PermissionCheckBox from "@/components/atomics/PermissionCheckBox.vue";
import Dropdown from "@/components/atomics/dropdown/BaseDropdown.vue";

export default defineComponent({
  name: "AdminManagementPopup",
  components: {
    Popup,
    FlatButton,
    ValidationTextField,
    PermissionCheckBox,
    Dropdown,
  },
  emits: ["onDone", "onError"],
  props: {
    companyId: { type: String, required: true },
    isInvite: { type: Boolean, default: false },
    dataRow: { type: Object as PropType<Admin>, default: null },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const adminData = ref(new Admin(props.dataRow));
    const state = reactive({
      editValueId: adminData.value.partnerCompanyId,
      list_referral_company: [] as Record<string, string>[],
    });

    const { getPreferences } =
      RepositoryFactory.getRepository<OnboardingRepository>(
        OnboardingRepository
      );

    const getPermissionName = (permission: keyof Admin) => {
      if (permission == "templateManagement")
        return t("adminManagement.templateManagement");
      if (permission == "companyAndMemberManagement")
        return (
          t("adminManagement.lstCompany") +
          "/" +
          t("adminManagement.customerList")
        );
      if (permission == "emailManagement")
        return t("adminManagement.emailManagement");
      if (permission == "adminManagement")
        return t("adminManagement.accountManagement");
      if (permission == "partnerManagement")
        return t("adminManagement.listOfPartners");
    };

    const selectFile = (id: string) => {
      form.values.partnerCompanyId = id;
    };

    const { closePopup } = usePopupControl();
    const popupKey: PopupKey = "adminManagement";

    const { updateAdmin, inviteAdmin, updateAdminInvitation } =
      RepositoryFactory.getRepository<AdminManagementRepository>(
        AdminManagementRepository
      );

    const popupAction = computed(() => {
      if (!props.dataRow?.id) {
        return inviteAdmin;
      } else if (props.isInvite) {
        return updateAdminInvitation;
      } else {
        return updateAdmin;
      }
    });

    const { formHandle, form } = useFormHandler(
      adminData.value,
      popupAction.value,
      "object"
    );

    const submit = async () => {
      try {
        await formHandle();
      } catch (error) {
        emit("onError");
        return;
      }

      emit("onDone");
      closePopup(popupKey);
    };

    onMounted(async () => {
      const response = await getPreferences();
      if (response) {
        state.list_referral_company = response;
      }
    });

    return {
      ...toRefs(state),
      popupKey,
      submit,
      adminData,
      getPermissionName,
      form,
      selectFile,
    };
  },
});
