import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, mergeProps as _mergeProps, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59bee990"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "label-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["label", { disabled: _ctx.$attrs.disabled == '' }])
  }, [
    _createElementVNode("span", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _withDirectives(_createElementVNode("input", _mergeProps({
      type: "checkbox",
      class: "checkbox mr-3"
    }, _ctx.$attrs, {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.readonlyValue) = $event))
    }), null, 16), [
      [_vModelCheckbox, _ctx.readonlyValue]
    ]),
    _withDirectives(_createElementVNode("input", _mergeProps({
      type: "checkbox",
      class: "checkbox mr-3"
    }, _ctx.$attrs, {
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.writeValue) = $event))
    }), null, 16), [
      [_vModelCheckbox, _ctx.writeValue]
    ])
  ], 2))
}