export interface BaseAdminI {
  id?: string;
  username?: string;
  email?: string;
  lastName?: string;
  firstName?: string;
}

export interface AdminPermissionI {
  isMasterAdmin: boolean;
  templateManagement: number;
  companyAndMemberManagement: number;
  emailManagement: number;
  adminManagement: number;
  partnerManagement: number;
  partnerCompanyId: string;
}

export interface AdminI extends BaseAdminI, AdminPermissionI {
  createdAt?: string;
  countPermission?: number;
}

export class BaseAdmin implements BaseAdminI {
  id = "";
  username = "";
  email = "";
  lastName = "";
  firstName = "";

  constructor(admin?: BaseAdminI) {
    Object.assign(this, admin);
  }
}

export default class Admin implements AdminI {
  id: string | undefined = undefined;
  email = "";
  emailPending = "";
  lastName = "";
  firstName = "";
  isMasterAdmin = false;
  templateManagement = 0;
  companyAndMemberManagement = 0;
  emailManagement = 0;
  adminManagement = 0;
  partnerManagement = 0;
  partnerCompanyId = "00000000-0000-0000-0000-432d6d696e64";
  createdAt = "";
  countPermission = 0;
  constructor(admin?: AdminI) {
    Object.assign(this, admin);
  }
}
