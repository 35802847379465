
import { defineComponent, computed } from "vue";
import { useField } from "vee-validate";

export default defineComponent({
  name: "PermissionCheckBox",
  components: {},
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: "",
    },
    isField: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { value: fieldValueReadonly } = useField(props.name, "", {
      initialValue: props.modelValue,
    });

    const { value: fieldValueWrite } = useField(props.name, "", {
      initialValue: props.modelValue,
    });

    const readonlyValue = computed({
      get(): boolean {
        if (props.modelValue > 0) {
          return true;
        } else {
          return false;
        }
      },

      set(value: boolean) {
        if (value) {
          fieldValueReadonly.value = 2;
        } else {
          fieldValueReadonly.value = 0;
        }
        emit("update:modelValue", fieldValueReadonly.value);
      },
    });

    const writeValue = computed({
      get(): boolean {
        if (props.modelValue == 1) {
          return true;
        } else {
          return false;
        }
      },

      set(value: boolean) {
        if (value) {
          fieldValueWrite.value = 1;
        } else {
          fieldValueWrite.value = 2;
        }
        emit("update:modelValue", fieldValueWrite.value);
      },
    });

    return {
      readonlyValue,
      writeValue,
    };
  },
});
