import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17848642"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "admin-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tag = _resolveComponent("Tag")!
  const _component_ListItemActionDropdown = _resolveComponent("ListItemActionDropdown")!

  return (_openBlock(), _createBlock(_component_ListItemActionDropdown, {
    "head-icon": require('icons/default-avatar.svg'),
    "is-avatar-head": "",
    actions: _ctx.actionDropdown,
    "has-divider": ""
  }, {
    "title-primary": _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.admin.lastName) + " " + _toDisplayString(_ctx.admin.firstName), 1)
    ]),
    "title-secondary": _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.admin.email), 1)
    ]),
    body: _withCtx(() => [
      _createVNode(_component_Tag, {
        text: 
          (_ctx.admin.countPermission || 0) + _ctx.$t('adminManagement.countPermission')
        ,
        class: "tag"
      }, null, 8, ["text"])
    ]),
    _: 1
  }, 8, ["head-icon", "actions"]))
}