
import { defineComponent, reactive, toRefs, computed } from "vue";
import Tabs from "@/components/atomics/Tabs.vue";
import FlatButton from "@/components/atomics/button/FlatButton.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import AdminManagementPopup from "@/components/popups/adminManagement/AdminManagementPopup.vue";
import { AdminManagementRepository, RepositoryFactory } from "@/lib/https";
import { useI18n } from "vue-i18n";
import AdminTitle from "./AdminTitle.vue";
import { AdminI } from "@/models/Admin";
import AfterLoginPage from "@/views/layouts/AfterLoginAdminPage.vue";
import usePopupControl from "@/lib/compositional-logic/usePopupControl";
import useNotificationControl from "@/lib/compositional-logic/useNotificationControl";
import useList from "@/lib/compositional-logic/useList";
import ConfirmDeleteAdminPopup from "@/components/popups/ConfirmPopup.vue";
import { AnonymousFunction } from "@/models/Function";
import useAccount from "@/lib/compositional-logic/useAccount";

export default defineComponent({
  name: "AdminManagement",
  components: {
    Tabs,
    AdminManagementPopup,
    ValidationTextField,
    AdminTitle,
    AfterLoginPage,
    FlatButton,
    ConfirmDeleteAdminPopup,
  },
  setup() {
    const { t } = useI18n();
    const state = reactive({
      dataRow: undefined as AdminI | undefined,
      currentTabIndex: 0,
      adminToDoAction: {
        id: "",
        title: t("member.deleteTitle"),
        message: "",
        action: AnonymousFunction,
        btnText: "",
      },
    });

    const { popupState, openPopup } = usePopupControl();
    const { openNotification } = useNotificationControl();
    const { permissions } = useAccount();

    const {
      getAdminList,
      getInviteAdminList,
      deleteAdmin,
      resendInviteAdmin,
      deleteAdminInvitation,
    } = RepositoryFactory.getRepository<AdminManagementRepository>(
      AdminManagementRepository
    );

    const {
      list: adminList,
      totalLength: adminTotalLength,
      loadMore: adminLoadMore,
      reloadList: adminReloadList,
      setFilterKeyword: adminSetFilterKeyword,
    } = useList({
      initialLoadList: async ({ pageSize }) => await getAdminList({ pageSize }),
      loadMoreList: async ({ page, pageSize, keyword }) =>
        await getAdminList({ page, pageSize, keyword }),
      filterList: async ({ keyword, pageSize }) =>
        await getAdminList({ keyword, pageSize }),
    });

    const {
      list: inviteList,
      totalLength: inviteTotalLength,
      loadMore: inviteLoadMore,
      reloadList: inviteReloadList,
      setFilterKeyword: inviteSetFilterKeyword,
    } = useList({
      initialLoadList: async ({ pageSize }) =>
        await getInviteAdminList({ pageSize }),
      loadMoreList: async ({ page, pageSize, keyword }) =>
        await getInviteAdminList({ page, pageSize, keyword }),
      filterList: async ({ keyword, pageSize }) =>
        await getInviteAdminList({ keyword, pageSize }),
    });

    const isInvite = computed(() => state.currentTabIndex == 1);

    const handleLoad = computed(() =>
      state.currentTabIndex == 0 ? adminLoadMore : inviteLoadMore
    );

    const tabTags = computed(() => [
      {
        name: t("adminManagement.tabParticipated"),
        total: adminTotalLength.value,
      },
      {
        name: t("adminManagement.tabInviting"),
        total: inviteTotalLength.value,
      },
    ]);

    const onListChanged = async () => {
      if (isInvite.value) {
        await inviteReloadList();
      } else {
        await adminReloadList();
      }
      openNotification();
    };

    const onShowActionPopup = (dataRow: AdminI | undefined = undefined) => {
      openPopup("adminManagement");
      state.dataRow = dataRow;
    };

    const onResendPopup = async (dataRow: AdminI | undefined = undefined) => {
      openPopup("confirmAction");
      if (!dataRow?.id) return;
      state.adminToDoAction.id = dataRow.id;
      state.adminToDoAction.btnText = t("common.sendTo");
      state.adminToDoAction.title = t("adminManagement.resendInvite");
      state.adminToDoAction.message = t("adminManagement.reSendMessage", {
        name: dataRow.lastName + " " + dataRow.firstName,
        email: dataRow.email,
      });
      state.adminToDoAction.action = (id: string) =>
        resendInviteAdmin(id, dataRow);
    };

    const onDeletePopup = async (
      { id = "", username = "", email = "" },
      isInviting: boolean
    ) => {
      openPopup("confirmAction");
      state.adminToDoAction.id = id;

      if (isInviting) {
        state.adminToDoAction.action = deleteAdminInvitation;
        state.adminToDoAction.title = t("adminManagement.cancelSend");
        state.adminToDoAction.btnText = t("common.cancel");
        state.adminToDoAction.message = t("adminManagement.cancelMessage", {
          name: username,
          email,
        });
      } else {
        state.adminToDoAction.action = deleteAdmin;
        state.adminToDoAction.title = t("adminManagement.deleteAdmin");
        state.adminToDoAction.message = t("adminManagement.deleteMessage", {
          name: username,
          email,
        });
      }
    };

    return {
      ...toRefs(state),
      onListChanged,
      popupState,
      onShowActionPopup,
      isInvite,
      tabTags,
      adminList,
      inviteList,
      adminSetFilterKeyword,
      inviteSetFilterKeyword,
      handleLoad,
      openNotification,
      onDeletePopup,
      onResendPopup,
      permissions,
    };
  },
});
