import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b2f27288"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-header" }
const _hoisted_2 = { class: "section-header" }
const _hoisted_3 = { class: "contract-colunm" }
const _hoisted_4 = { class: "permission-colunm" }
const _hoisted_5 = {
  key: 0,
  class: "table-admin"
}
const _hoisted_6 = {
  key: 1,
  class: "table-admin"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tabs = _resolveComponent("Tabs")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_ValidationTextField = _resolveComponent("ValidationTextField")!
  const _component_AdminTitle = _resolveComponent("AdminTitle")!
  const _component_AfterLoginPage = _resolveComponent("AfterLoginPage")!
  const _component_AdminManagementPopup = _resolveComponent("AdminManagementPopup")!
  const _component_ConfirmDeleteAdminPopup = _resolveComponent("ConfirmDeleteAdminPopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AfterLoginPage, { onScrolledToBottom: _ctx.handleLoad }, {
      "top-part": _withCtx(() => [
        _createVNode(_component_Tabs, {
          "onCurrentTab:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.currentTabIndex = $event)),
          "tab-tags": _ctx.tabTags
        }, null, 8, ["tab-tags"])
      ]),
      "right-part": _withCtx(() => [
        _createVNode(_component_FlatButton, {
          onOnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onShowActionPopup())),
          text: _ctx.$t('adminManagement.addAdmin'),
          icon: require('icons/add-white.svg'),
          class: "add-btn"
        }, null, 8, ["text", "icon"])
      ]),
      "left-top-part": _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _withDirectives(_createVNode(_component_ValidationTextField, {
            "prefix-icon": require('icons/search-placeholder.svg'),
            "suffix-icon": require('icons/close.svg'),
            placeholder: _ctx.$t('adminManagement.searchTemplate'),
            "onUpdate:value": _ctx.adminSetFilterKeyword,
            "is-delete-on-suffix": ""
          }, null, 8, ["prefix-icon", "suffix-icon", "placeholder", "onUpdate:value"]), [
            [_vShow, _ctx.currentTabIndex == 0]
          ]),
          _withDirectives(_createVNode(_component_ValidationTextField, {
            "prefix-icon": require('icons/search-placeholder.svg'),
            "suffix-icon": require('icons/close.svg'),
            placeholder: _ctx.$t('adminManagement.searchTemplate'),
            "is-delete-on-suffix": "",
            "onUpdate:value": _ctx.inviteSetFilterKeyword
          }, null, 8, ["prefix-icon", "suffix-icon", "placeholder", "onUpdate:value"]), [
            [_vShow, _ctx.currentTabIndex == 1]
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("adminManagement.contractList")), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("adminManagement.permission")), 1)
          ])
        ])
      ]),
      "left-bottom-part": _withCtx(() => [
        (_ctx.currentTabIndex == 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.adminList, (admin, index) => {
                return (_openBlock(), _createBlock(_component_AdminTitle, {
                  key: index,
                  admin: admin,
                  disabled: _ctx.permissions.adminManagement != 1,
                  onOnShowActionPopup: _ctx.onShowActionPopup,
                  onOnDeleteAdmin: _ctx.onDeletePopup
                }, null, 8, ["admin", "disabled", "onOnShowActionPopup", "onOnDeleteAdmin"]))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.inviteList, (admin, index) => {
                return (_openBlock(), _createBlock(_component_AdminTitle, {
                  key: index,
                  admin: admin,
                  "is-inviting": "",
                  disabled: _ctx.permissions.adminManagement != 1,
                  onOnShowActionPopup: _ctx.onShowActionPopup,
                  onOnDeleteAdmin: _ctx.onDeletePopup,
                  onOnResendAction: _ctx.onResendPopup
                }, null, 8, ["admin", "disabled", "onOnShowActionPopup", "onOnDeleteAdmin", "onOnResendAction"]))
              }), 128))
            ]))
      ]),
      _: 1
    }, 8, ["onScrolledToBottom"]),
    (_ctx.popupState.adminManagement)
      ? (_openBlock(), _createBlock(_component_AdminManagementPopup, {
          key: 0,
          "is-invite": _ctx.isInvite,
          "data-row": _ctx.dataRow,
          onOnDone: _ctx.onListChanged,
          onOnError: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openNotification({ type: 'error' })))
        }, null, 8, ["is-invite", "data-row", "onOnDone"]))
      : _createCommentVNode("", true),
    (_ctx.popupState.confirmAction)
      ? (_openBlock(), _createBlock(_component_ConfirmDeleteAdminPopup, _mergeProps({ key: 1 }, _ctx.adminToDoAction, { onOnDone: _ctx.onListChanged }), null, 16, ["onOnDone"]))
      : _createCommentVNode("", true)
  ], 64))
}