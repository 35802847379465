
import { defineComponent } from "vue";
import ListItemActionDropdown from "@/components/atomics/list-item/ListItemActionDropdown.vue";
import Tag from "@/components/atomics/Tag.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "AdminTitle",
  components: {
    ListItemActionDropdown,
    Tag,
  },
  props: {
    admin: { type: Object, required: true },
    isInviting: { type: Boolean, default: false },
    disabled: { type: Boolean },
  },
  emits: ["onShowActionPopup", "onDeleteAdmin", "onResendAction"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const actionDropdown = [
      ...(props.isInviting
        ? [
            {
              action: () => {
                emit("onResendAction", props.admin);
              },
              icon: "icons/re_invite.svg",
              text: t("adminManagement.resendInvite"),
              disabled: props.disabled,
            },
          ]
        : []),
      {
        action: () => {
          emit("onShowActionPopup", props.admin);
        },
        icon: "icons/edit.svg",
        text: t("adminManagement.edit"),
        disabled: props.disabled,
      },
      {
        action: () => {
          emit(
            "onDeleteAdmin",
            {
              id: props.admin.id,
              username: props.admin.lastName + " " + props.admin.firstName,
              email: props.admin.email,
            },
            props.isInviting
          );
        },
        icon: "icons/delete.svg",
        text: t("adminManagement.delete"),
        disabled: props.disabled,
        isDelete: true,
      },
    ];

    return {
      actionDropdown,
    };
  },
});
