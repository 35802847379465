import { reactive, Ref, ref, computed } from "vue";
import store from "@/store";

interface Permissions {
  isMasterAdmin: boolean;
  templateManagement: number;
  companyAndMemberManagement: number;
  emailManagement: number;
  adminManagement: number;
  partnerManagement: number;
  partnerCompanyId: string;
}

interface AccountInfo {
  firstName: Ref<string>;
  lastName: Ref<string>;
  email: Ref<string>;
  emailPending: Ref<string>;
  username: Ref<string>;
  permissions: Permissions;
  getNew: () => void;
}

export default function useAccount(): AccountInfo {
  const username = ref("");
  const firstName = ref("");
  const lastName = ref("");
  const email = ref("");
  const emailPending = ref("");
  const permissions = reactive({
    isMasterAdmin: false,
    templateManagement: 0,
    companyAndMemberManagement: 0,
    emailManagement: 0,
    adminManagement: 0,
    partnerManagement: 0,
    partnerCompanyId: "",
  });

  const getNew = () => {
    const response = computed(() => store.state.account);
    email.value = response.value.email;
    username.value = `${response.value.lastName} ${response.value.firstName}`;
    firstName.value = response.value.firstName;
    lastName.value = response.value.lastName;
    emailPending.value = response.value.emailPending;

    permissions.isMasterAdmin = response.value.isMasterAdmin ?? false;
    permissions.templateManagement = response.value.templateManagement ?? 0;
    permissions.companyAndMemberManagement =
      response.value.companyAndMemberManagement ?? 0;
    permissions.emailManagement = response.value.emailManagement ?? 0;
    permissions.adminManagement = response.value.adminManagement ?? 0;
    permissions.partnerManagement = response.value.partnerManagement ?? 0;
    permissions.partnerCompanyId = response.value.partnerCompanyId ?? "";
  };
  getNew();

  return {
    firstName,
    lastName,
    username,
    email,
    emailPending,
    permissions,
    getNew,
  };
}
